import React from "react";
import classNames from "./landing.module.scss";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
//assets
import cloud from "../../../assets/images/global/cloud.svg";
import cloudTransparent from "../../../assets/images/global/cloudstransparent.svg";
import finace from "../../../assets/images/pages/home/landing/dashboards/finace.png";
import accountants from "../../../assets/images/pages/home/landing/dashboards/accountants.png";
import finaid from "../../../assets/images/pages/home/landing/dashboards/finaid.png";
import accute from "../../../assets/images/pages/home/landing/dashboards/accute.png";

const Landing = () => {
  const history = useHistory();

  return (
    <div className={classNames.landing}>
      <div className={classNames.cloudContainer}>
        <img src={cloud} alt="cloud" />
        <img src={cloudTransparent} alt="cloudTransparent" />
      </div>
      <motion.div
        className={classNames.appsCard}
        initial={{ height: 0 }}
        whileInView={{ height: "420px", transition: { duration: 1.5 } }}
      >
        <div>
          <img src={finace} alt="finace" />
        </div>
        <div onClick={() => window.open("https://accountants.io/")}>
          <img src={accountants} alt="accountants" />
        </div>
        <div onClick={() => window.open("https://finaid.io/")}>
          <img src={finaid} alt="finaid" />
        </div>
        <div onClick={() => window.open("https://accute.io/")}>
          <img src={accute} alt="accute" />
        </div>
        <span className={`${classNames.title} ${classNames.title1}`}>
          Revolutionizing Accounting
        </span>
        <span className={`${classNames.title} ${classNames.title2}`}>
          and Tax Solutions
        </span>
      </motion.div>
      <div className={classNames.desc}>
        At <span>FinACE</span>, we are committed to transforming the landscape
        of accounting and tax practices with our suite of{" "}
        <span>innovative SaaS products</span>. Each of our offerings is designed
        to not only stand out individually but also work seamlessly together,
        creating a powerful ecosystem we proudly call the{" "}
        <span>FinACEverse</span>.
      </div>
      <div
        className={classNames.demoBtn}
        onClick={() => history.push("/waitinglist")}
      >
        Join Waitlist
      </div>
    </div>
  );
};

export default Landing;
