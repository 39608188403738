import React, { useRef } from "react";
import classNames from "./integrate.module.scss";

//assets
import integration1 from "../../../assets/images/pages/home/integrations/integration1.svg";
import integration2 from "../../../assets/images/pages/home/integrations/integration2.png";
import integration3 from "../../../assets/images/pages/home/integrations/integration3.svg";
import integration4 from "../../../assets/images/pages/home/integrations/integration4.svg";
import integration5 from "../../../assets/images/pages/home/integrations/integration5.svg";
import integration6 from "../../../assets/images/pages/home/integrations/integration6.svg";
import integration7 from "../../../assets/images/pages/home/integrations/integrations7.png";
import { motion, useInView, useScroll, useTransform } from "framer-motion";

const ImageBlock = ({ index, src, alt }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.5 });
  const { scrollYProgress } = useScroll();

  const y = useTransform(scrollYProgress, [0, 1], [100, -100], { clamp: true });
  const opacity = useTransform(scrollYProgress, [0, 0.5, 1], [0, 1, 0]);

  return (
    <motion.div
      ref={ref}
      className={classNames.gridBlock}
      style={{
        opacity: isInView ? 1 : opacity,
        y: isInView ? 0 : y,
        filter: isInView ? "blur(0px)" : "blur(2px)",
        willChange: "transform, opacity, filter",
        background: "white",
      }}
      initial={{ z: 400 }}
      animate={{ z: isInView ? 0 : 400 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <img src={src} alt={alt} style={{ willChange: "transform" }} />
    </motion.div>
  );
};

const Integrate = () => {
  const containerRef = useRef(null);

  const images = [
    { index: 4, src: integration1, alt: "integration1" },
    { index: 20, src: integration2, alt: "integration2" },
    { index: 26, src: integration3, alt: "integration3" },
    { index: 34, src: integration4, alt: "integration4" },
    { index: 51, src: integration5, alt: "integration5" },
    { index: 68, src: integration6, alt: "integration6" },
    { index: 43, src: integration7, alt: "integration7" },
  ];

  return (
    <div className={classNames.integrate}>
      <div className={`${classNames.integrationGrids} ${classNames.onlyGrids}`}>
        {Array.from({ length: 73 }).map((_, index) => (
          <div
            key={`bg-${index}`}
            className={
              index === 0 ? classNames.gridTitle : classNames.gridBlock
            }
          />
        ))}
      </div>
      <div
        className={`${classNames.integrationGrids} ${classNames.contentDiv}`}
        ref={containerRef}
        style={{ position: "sticky", top: 0 }}
      >
        {Array.from({ length: 73 }).map((_, index) => {
          const image = images.find((img) => img.index === index);

          if (image) {
            return <ImageBlock key={`img-${index}`} {...image} />;
          }

          if (index === 0) {
            return (
              <motion.div
                key={`title-${index}`}
                className={classNames.gridTitle}
              >
                <div className={classNames.title}>
                  <div>Seamless integrations</div>
                  <div>with your accountants</div>
                </div>
                <div className={classNames.submitBtn}>See all Integrations</div>
              </motion.div>
            );
          }

          return (
            <div key={`empty-${index}`} className={classNames.gridBlock} />
          );
        })}
      </div>
    </div>
  );
};

export default Integrate;
