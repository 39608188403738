import React, { useState } from "react";
import classNames from "./navbar.module.scss";

//assets
import logo from "../../../assets/images/logowithname.svg";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoClose } from "react-icons/io5";
import { useHistory } from "react-router-dom";

const NavbarNew = () => {
  const [mobileLinksOpen, setMobileLinksOpen] = useState(false);
  const history = useHistory();

  return (
    <div className={classNames.navbar}>
      <div className={classNames.leftContainer}>
        <img className={classNames.mainLogo} src={logo} alt="logo" />
        <div className={classNames.navigations}>
          <div>About Us</div>
          <div>Solutions</div>
          <div>Services</div>
          <div>Academy</div>
        </div>
      </div>
      <div className={classNames.btnsContainer}>
        <div className={classNames.loginBtn}>
          <span onClick={() => history.push("/login")}>Log in</span>
        </div>
        <div
          className={classNames.manifestBtn}
          onClick={() => history.push("/waitinglist")}
        >
          Join Waitlist
        </div>
      </div>
      <div
        className={classNames.linksMobile}
        // style={{ overflow: mobileLinksOpen ? "" : "hidden" }}
      >
        <div onClick={() => setMobileLinksOpen((prev) => !prev)}>
          <RxHamburgerMenu
            style={{
              opacity: mobileLinksOpen ? 0 : "",
              transform: mobileLinksOpen
                ? "translate(-50%, -50%) rotate(360deg)"
                : "",
            }}
          />
          <IoClose
            style={{
              opacity: !mobileLinksOpen ? 0 : "",
              transform: !mobileLinksOpen
                ? "translate(-50%, -50%) rotate(360deg)"
                : "",
            }}
          />
        </div>
        <div
          className={classNames.linksDropdown}
          style={{
            top: mobileLinksOpen ? "" : "-50px",
            right: mobileLinksOpen ? "" : "-50px",
            height: mobileLinksOpen ? "" : "0",
            width: mobileLinksOpen ? "" : "0",
            opacity: mobileLinksOpen ? "" : "0",
          }}
        >
          <div className={classNames.loginBtn}>
            <span onClick={() => history.push("/login")}>Log in</span>
          </div>
          <div
            className={classNames.manifestBtn}
            onClick={() => history.push("/waitinglist")}
          >
            Join Waitlist
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarNew;
