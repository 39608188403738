import React, { useRef } from "react";
import classNames from "./desc.module.scss";
import { motion, useScroll, useTransform } from "framer-motion";

const DescriptionComponent = () => {
  const ref = useRef(null); // Reference to track the scroll position of the component

  // Use scrollYProgress but attach it to the specific ref of MarketPlace
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start center", "center center"], // Track scroll from start to the middle of the component
  });

  // Transform for the odd cards (moves from 100px to 0px)
  const yTransform = useTransform(scrollYProgress, [0, 1], [100, 0]);
  // Opacity changes from 0 to 1
  const opacityTransform = useTransform(scrollYProgress, [0, 1], [0, 1]);

  return (
    <motion.div
      className={classNames.desc}
      ref={ref}
      style={{ y: yTransform, opacity: opacityTransform }}
      initial={false}
      transition={{
        duration: 0.5,
        ease: "easeOut",
      }}
    >
      <div>
        Finace offers an all-in-one suite to <span>automate accounting</span>{" "}
        practices. It provides tools for bookkeeping, client management, and
        financial reporting to help <span>streamline their workflows</span>.
      </div>
      <div>
        The platform integrates services to <span>eliminate manual tasks</span>{" "}
        and enhance firm efficiency. By digitizing financial data, it ensures
        smooth communication between <span>accountants and clients</span>,
        modernizing practices with a focus on{" "}
        <span>automation and productivity</span>.
      </div>
    </motion.div>
  );
};

export default DescriptionComponent;
