import React from "react";
import classNames from "./offerings.module.scss";
import { motion } from "framer-motion";
import { allOfferings } from "../../../assets/data";
import GlowingDot, { SemiCircularGlow } from "../../Custom/GlowDot";

const Offerings = () => {
  return (
    <div className={classNames.offerings}>
      <SemiCircleCards />
    </div>
  );
};

export default Offerings;

const cardVariants = {
  hover: { scale: 1.2, transition: { duration: 0.3 } },
  tap: { scale: 0.9 },
};

const SemiCircleCards = () => {
  return (
    <div className={classNames.semiCircleContainer}>
      {allOfferings.map((eachOffering, index) => (
        <div key={index} className={classNames.card}>
          {eachOffering?.icon}
          <div className={classNames.title}>{eachOffering?.title}</div>
        </div>
      ))}
      <div className={classNames.contentContainer}>
        <div className={classNames.semiCircleGlow}>
          <SemiCircularGlow
            width={700}
            height={350}
            color="#d6a86b66"
            lighterColor="#d6a86b66"
            duration={6}
          />
        </div>
        <div className={classNames.heading}>Our Offerings</div>
        <div className={classNames.title}>
          <div>Explore the suite of </div>
          <div>AI-Powered Products</div>
        </div>
        <div className={classNames.discoverBtn}>Discover now</div>
      </div>
    </div>
  );
};
