import React from "react";

const GlowingDot = ({ size = 200, color = "#d6a86b", duration = 3 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 100 100">
      <defs>
        <radialGradient id="glow" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
          <stop offset="0%" stopColor={color} stopOpacity="0.8" />
          <stop offset="100%" stopColor={color} stopOpacity="0" />
        </radialGradient>
      </defs>

      {[0, 1, 2].map((i) => (
        <circle key={i} cx="50" cy="50" r="5" fill="url(#glow)">
          <animate
            attributeName="r"
            values="5;40"
            dur={`${duration}s`}
            repeatCount="indefinite"
            begin={`${(i * duration) / 3}s`}
          />
          <animate
            attributeName="opacity"
            values="0.8;0"
            dur={`${duration}s`}
            repeatCount="indefinite"
            begin={`${(i * duration) / 3}s`}
          />
        </circle>
      ))}

      <circle cx="50" cy="50" r="5" fill={color} />
    </svg>
  );
};

export default GlowingDot;

export const SemiCircularGlow = ({
  width = 400,
  height = 300,
  color = "#a3e5e0",
  lighterColor = "#a3e5e080",
  duration = 6,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 400 300">
      <defs>
        <radialGradient
          id="glowGradient"
          cx="50%"
          cy="100%"
          r="70%"
          fx="50%"
          fy="100%"
        >
          <stop offset="0%" stopColor={lighterColor} stopOpacity="1" />{" "}
          {/* Lighter color */}
          <stop offset="30%" stopColor={color} stopOpacity="1" />{" "}
          {/* Original color */}
          <stop offset="70%" stopColor={color} stopOpacity="0.3" />
          <stop offset="100%" stopColor={color} stopOpacity="0" />
        </radialGradient>
      </defs>
      {[0, 1, 2].map((i) => (
        <path key={i} d="M200,300 A1,1 0 0,1 200,300" fill="url(#glowGradient)">
          <animate
            attributeName="d"
            values={`M200,300 A1,1 0 0,1 200,300; M-100,300 A300,300 0 0,1 500,300`}
            dur={`${duration}s`}
            repeatCount="indefinite"
            begin={`${(i * duration) / 2}s`}
          />
          <animate
            attributeName="opacity"
            values="1;0"
            dur={`${duration}s`}
            repeatCount="indefinite"
            begin={`${(i * duration) / 2}s`}
          />
        </path>
      ))}
    </svg>
  );
};
