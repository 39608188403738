//assets
import { ReactComponent as PerformanceIcon } from "../images/icons/performance.svg";
import { ReactComponent as EngagementIcon } from "../images/icons/engagement.svg";
import { ReactComponent as GoalsIcon } from "../images/icons/goals.svg";
import { ReactComponent as AiIcon } from "../images/icons/ai.svg";
import { ReactComponent as GrowIcon } from "../images/icons/grow.svg";
import { ReactComponent as OfferingsIcon } from "../images/icons/offerings.svg";

import { ReactComponent as AccountantsIcon } from "../images/icons/accountants.svg";
import { ReactComponent as AccuteIcon } from "../images/icons/accute.svg";
import { ReactComponent as FinaceverseIcon } from "../images/icons/finaceverse.svg";
import { ReactComponent as FinaiassuredIcon } from "../images/icons/finaiassured.svg";
import { ReactComponent as LucaIcon } from "../images/icons/luca.svg";
import { ReactComponent as MyCabinetIcon } from "../images/icons/mycabinet.svg";

import ceoImage from "../images/pages/home/landing/image/ceo.png";
import cto1 from "../images/pages/home/landing/image/cto86.png";
import cto2 from "../images/pages/home/landing/image/cto87.png";
import cto3 from "../images/pages/home/landing/image/cto88.png";
import cto4 from "../images/pages/home/landing/image/cto89.png";
import cto5 from "../images/pages/home/landing/image/cto90.png";
import cto6 from "../images/pages/home/landing/image/cto91.png";

export const allSolutions = [
  {
    title: "Fin(AI)ssured",
    desc: "We enhance essential accounting operations by automating and safeguarding all processes, from handling financial data management to reporting to stakeholders, ensuring accuracy.",
  },
  {
    title: "Accountants.io",
    desc: "Accountants.io is a global online marketplace where accountants and tax practitioners can discover assignments tailored to their expertise. This platform connects professionals with clients worldwide, expanding opportunities and fostering a network of excellence.",
  },
  {
    title: "Luca",
    desc: "Meet Luca, our AI-based bot designed to answer clients' tax and accounting questions with precision. Luca supports CPA firms, accountants, and tax practitioners in brainstorming strategies and planning assignments, streamlining client interactions and enhancing service delivery.",
  },
  {
    title: "Accute",
    desc: "Accute is an AI-driven workflow automation tool that helps manage tasks promptly. Acting as a CRM, it provides regular updates to clients, ensuring transparency and enhancing the efficiency of accounting and tax practices.",
  },
  {
    title: "MyCabinet",
    desc: "Mycabinet offers a secure portal for clients to share sensitive documents with their accountants. With view-only access features, it ensures that critical business data remains protected while being easily accessible to authorized professionals.",
  },
];

export const allOfferings = [
  { title: "Accountants", icon: <AccountantsIcon /> },
  { title: "Accute", icon: <AccuteIcon /> },
  { title: "Finaid", icon: <FinaceverseIcon /> },
  { title: "Finaiassured", icon: <FinaiassuredIcon /> },
  { title: "Luca", icon: <LucaIcon /> },
  { title: "MyCabinet", icon: <MyCabinetIcon /> },
];

export const allTeam = [
  {
    name: "Vithal V CPA (US) | Global (MBA) | (M.Com)",
    role: "CEO",
    pic: ceoImage,
    quotes: ``,
  },
  {
    name: "Vinod Ravi (M.Com)",
    role: "Chief Operations Officer - Services",
    pic: cto1,
    quotes: "",
  },
  {
    name: "Pavan VKR (B.Com)",
    role: "Chief Relationship Officer",
    pic: cto2,
    quotes: "",
  },
  {
    name: "Dipthi Bobba (Global MBA)",
    role: "Chief Delivery Officer",
    pic: cto3,
    quotes: "",
  },
  {
    name: "Tarun Challa (CPA(US),CPA(Canada))",
    role: "Chief Finance Officer",
    pic: cto4,
    quotes: "",
  },
  {
    name: "Pavan Balusu (M.Com)",
    role: "Chief Marketing Officer",
    pic: cto5,
    quotes: "",
  },
  {
    name: "Shorupan Pirakaspathy",
    role: "Chief Technology Officer",
    pic: cto6,
    quotes: "",
  },
];
