import React, { useRef, useState } from "react";
import classNames from "./suiteoftootls.module.scss";

import YouTube from "react-youtube";
import { allSolutions } from "../../../assets/data";
import GlowingDot from "../../Custom/GlowDot";
import { motion, useScroll, useTransform } from "framer-motion";

const SuiteOfTools = () => {
  const [selectedFeature, setSelectedFeature] = useState("Fin(AI)ssured");

  const ref = useRef(null); // Reference to track the scroll position of the component

  // Use scrollYProgress but attach it to the specific ref of MarketPlace
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start 80%", "start 70%"], // Track scroll from start to the middle of the component
  });

  const opacityTransform = useTransform(scrollYProgress, [0, 1], [0, 1]);

  return (
    <div className={classNames.suiteOfTools} ref={ref}>
      <div className={classNames.heading}>Solutions</div>
      <div className={classNames.title}>
        Our Suite Of <div>Ai-Enabled Tools</div>
      </div>
      <div className={classNames.desc}>
        We enhance essential accounting operations by automating and
        safeguarding all processes, from handling financial data management to
        reporting to stakeholders, ensuring accuracy and efficiency.
      </div>
      <motion.div
        className={classNames.allCards}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, transition: { duration: 2 } }}
      >
        <div>
          <EachCard
            {...allSolutions[0]}
            stateValue={selectedFeature}
            setState={setSelectedFeature}
          />
          <EachCard
            {...allSolutions[1]}
            stateValue={selectedFeature}
            setState={setSelectedFeature}
          />
        </div>
        <div className={classNames.iframeBox}>
          {selectedFeature == "Fin(AI)ssured" ? (
            <YouTubeEmbed videoId="xvpNglrVVQc" />
          ) : selectedFeature === "Accute" ? (
            <YouTubeEmbed videoId="-JuaD4pLxr8" />
          ) : selectedFeature === "Accountants.io" ? (
            <YouTubeEmbed videoId="Sov1butxN2o" />
          ) : (
            ""
          )}
          <EachCard
            {...allSolutions[2]}
            stateValue={selectedFeature}
            setState={setSelectedFeature}
          />
        </div>
        <div>
          <EachCard
            {...allSolutions[3]}
            stateValue={selectedFeature}
            setState={setSelectedFeature}
          />
          <EachCard
            {...allSolutions[4]}
            stateValue={selectedFeature}
            setState={setSelectedFeature}
          />
        </div>
      </motion.div>
      <motion.div
        className={classNames.glowRay}
        style={{ opacity: opacityTransform }}
        initial={false}
        transition={{
          duration: 0.5,
          ease: "easeOut",
        }}
      >
        <GlowingDot size={90} duration={4} />
      </motion.div>
    </div>
  );
};

export default SuiteOfTools;

const YouTubeEmbed = ({ videoId }) => {
  const opts = {
    width: "100%",
  };

  return (
    <YouTube
      className={classNames.youtubePlayer}
      videoId={videoId}
      opts={opts}
    />
  );
};

const EachCard = ({ title, desc, setState, stateValue }) => {
  return (
    <div
      className={`${classNames.eachCard} ${
        stateValue === title && classNames.cardSelected
      }`}
      style={{
        background:
          stateValue == "Fin(AI)ssured" && title === "Fin(AI)ssured"
            ? "#D6A86B"
            : stateValue === "Accountants.io" && title === "Accountants.io"
            ? "#191F41"
            : stateValue === "Luca" && title === "Luca"
            ? "#59A2DD"
            : stateValue === "Accute" && title === "Accute"
            ? "#FFBF91"
            : stateValue === "MyCabinet" && title === "MyCabinet"
            ? "#983A78"
            : "",
      }}
      onClick={() => setState(title)}
    >
      <div className={classNames.title}>{title}</div>
      <div className={classNames.desc}>{desc}</div>
    </div>
  );
};
