import React, { useState } from "react";
import classNames from "./comparison.module.scss";
import NavbarNew from "../../components/Home/NavbarNew";
import Footer from "../../components/HomeOld/Footer";

//assets
import finaceBox from "../../assets/images/pages/home/comparison/finacebox.svg";
import trullionBox from "../../assets/images/pages/home/comparison/trullionbox.svg";

const Comparison = () => {
  const [selectedFeature, setSelectedFeature] = useState("Ai Capability");

  const allFeatures = [
    "Ai Capability",
    "Tech Features",
    "Support & Success",
    "Tech Features",
    "Time To Value (ROI)",
    "Costs",
    "Scalability",
  ];

  return (
    <div className={classNames.comparison}>
      <NavbarNew />
      <div className={classNames.wrapper}>
        <div className={classNames.logos}>
          <img src={finaceBox} alt="finaceBox" />
          <img src={trullionBox} alt="trullionBox" />
        </div>
        <div className={classNames.title}>Finace Vs Trullion</div>
        <div className={classNames.desc}>
          We enhance essential accounting operations by automating and
          safeguarding all processes, from handling financial data management to
          reporting to stakeholders, ensuring accuracy and efficiency.
        </div>
        <div className={classNames.bigBox}>
          <div className={classNames.leftBox}>
            {allFeatures?.length > 0 &&
              allFeatures?.map((eachItem, index) => {
                return (
                  <div
                    className={`${classNames.eachOption} ${
                      selectedFeature === eachItem
                        ? classNames.selectedFeature
                        : ""
                    }`}
                    key={eachItem + index}
                    onClick={() => {
                      setSelectedFeature(eachItem);
                    }}
                  >
                    {eachItem}
                  </div>
                );
              })}
          </div>
          <div className={classNames.rightBox}>{selectedFeature}</div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Comparison;
