import React from "react";
import Comparison from "../../components/HomeOld/Comparison";
import NavbarNew from "../../components/Home/NavbarNew";

const ComparisonPage = () => {
  return (
    <div>
      <NavbarNew />
      <Comparison />
    </div>
  );
};

export default ComparisonPage;
