import React, { useState } from "react";
import classNames from "./team.module.scss";
import { allTeam } from "../../../assets/data";

const Team = () => {
  const [selectedMember, setSelectedMember] = useState(
    "Vithal V CPA (US) | Global (MBA) | (M.Com)"
  );

  return (
    <div className={classNames.suiteOfTools}>
      <div className={classNames.title}>Meet our Team</div>
      <div className={`${classNames.allCards} ${classNames.allCardsMobile}`}>
        {allTeam?.map((eachMember, index) => {
          return (
            <div
              className={`${classNames.eachTeamMember} ${
                selectedMember === eachMember?.name &&
                classNames.selectedTeamMember
              }`}
              key={eachMember?.name + index}
              onClick={() => setSelectedMember(eachMember?.name)}
            >
              <div className={classNames.teamProfile}>
                <img src={eachMember?.pic} alt={eachMember?.name} />
              </div>
              <div className={classNames.teamDetail}>
                <div className={classNames.quotes}>{eachMember?.quotes}</div>
                <div className={classNames.details}>
                  <div>{eachMember?.name}</div>
                  <div>{eachMember?.role}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Team;
