import React, { useContext, useEffect, useRef, useState } from "react";
import "./forgotpassword.scss"
import { toast, ToastContainer } from "react-toastify";
import { toastify } from "../../assets/functions/toastify";
import fullLogo from "../../assets/images/logo.svg";



//assets
// import {
//   IoMdArrowDropdown,
//   IoMdArrowDropup,
// } from "react-icons/io";
import { useHistory } from "react-router-dom";
// import { GlobalContext } from "../../../context/Globalcontext";
import axios from "axios";

const ForgotPassword = () => {
    const navigate = useHistory();
    const [newPassword, setNewPassword] = useState("")

    //   let urldata

    //   useEffect(()=>{
    //     urldata = window.location.href.split("?token=")
    //     console.log(urldata , "urldata")
    //   },[])




    const SubmitPassword = async () => {
        // console.log(urldata[1] , "urldata-11")
        let token = window.location.href.split("?token=")[1]
        let resetPassword = await axios.post(`https://newauth.apimachine.com/v1/user/reset-password`, { newPassword }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log(resetPassword, "reset-pass")
        if (!resetPassword.status) {
            toastify("Unauthorized");
        }
        if (resetPassword.status == 200) {
            toast.success(resetPassword.data.message)
        }
    }

    return (
        <div className="datadisplay">
            <img src={fullLogo} alt="fullLogo" style={{ margin: "6rem 0" }} />
            <p className="titlefield">Forgot Password</p>
            <br />
            <div>
                <p>Enter New Password</p>
                <br />
                <input className="inputreset" type="text" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="Enter New Password" />
                <div className="forgotbtn" onClick={SubmitPassword}>Submit</div>
            </div>
        </div>
    );
};

export default ForgotPassword;
