import React from "react";
import classNames from "./home.module.scss";
import NavbarNew from "../../components/Home/NavbarNew/index.jsx";
import Landing from "../../components/Home/Landing/index.jsx";
import DescriptionComponent from "../../components/Home/desc/index.jsx";
import SuiteOfTools from "../../components/Home/SuiteOfTools/index.jsx";
import Services from "../../components/Home/Services/index.jsx";
import Offerings from "../../components/Home/offerings/index.jsx";
import Team from "../../components/Home/Team/index.jsx";
import Footer from "../../components/Home/Footer/index.jsx";
import Integrate from "../../components/Home/Integrate/index.jsx";
import Finaceverse from "../../components/Home/Finaceverse/index.jsx";

const HomePage = () => {
  return (
    <div className={classNames.home}>
      <NavbarNew />
      <Landing />
      <DescriptionComponent />
      <div className={classNames.allComponents}>
        <SuiteOfTools />
        {/* <Services /> */}
        <Finaceverse />
        <Offerings />
        <Team />
        <Integrate />
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
